import React from 'react'
import styles from './footer.module.css'
import { Link } from 'gatsby'

export default function Footer() {
  return (
    <footer>
      <div className={styles.links}>
        <Link to="/about/">このサイトについて</Link>&nbsp;|&nbsp;
        <Link to="/terms_of_use/">利用規約</Link>&nbsp;|&nbsp;
        <a href="https://circlearound.co.jp/privacy" target="_blank" rel="noreferrer"
          alt="プライバシーポリシー" title="プライバシーポリシー"
        >プライバシーポリシー</a>&nbsp;|&nbsp;
        <a href="https://circlearound.co.jp" target="_blank" rel="noreferrer"
          alt="サークルアラウンド株式会社" title="サークルアラウンド株式会社"
        >運営会社</a>
      </div>

      <div className={styles.companyName}>
        &copy; CIRCLE AROUND Inc.
      </div>

      <div>
        <a href="https://circlearound.co.jp" target="_blank" rel="noreferrer"
            alt="サークルアラウンド株式会社" title="サークルアラウンド株式会社">
          <img
            className={styles.logo}
            alt="サークルアラウンド株式会社"
            src={"/images/logo_white.png"}
          ></img>
        </a>          
      </div>

    </footer>
  )
}