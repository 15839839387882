import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'

export default () => { 
  return (
  <nav role="navigation">
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/">
          <h1 className={styles.logo}>
            <img 
              alt="Tech lib"
              src={"/images/logo.png"}
            ></img>
          </h1>
        </Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/entries/">一覧</Link>
      </li>
    </ul>
  </nav>
)
}
